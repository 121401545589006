const WhyUs = () => {
  return (
    <section className="why container mx-auto px-5 md:px-10 ">
      <div className="why-container flex flex-col md:flex-row gap-10">
        <div className="left basis-1/2">
          <p>About Us</p>
          <h1 className="">Why the Kofi Annan Institute for Conflict Transformation</h1>
          <p>
            The Kofi Annan Institute for Conflict Transformation (KAICT) was set
            up in 2006, and named after Kofi Atta Annan to honor the
            contributions to peace and security in Liberia by the former
            Ghanaian Secretary General of the United Nations (1997-2006). Under
            Annan’s leadership, the UN Security Council set up the United
            Nations Mission in Liberia (UNMIL) in 2003, which supported the
            implementation of the ceasefire agreement and the Liberian peace
            process until 2018 with the largest contingent of peacekeepers
            deployed thus far. 
          </p>
          
        </div>
        <div className="right basis-1/2">
          <img src="images/about/team.png" alt="Why Kofi Annan Institute" />
        </div>

      </div>
      <div className="my-6">
        <p className="text-justify">KAICT is a center of excellence for research,
            teaching, policy dialogue and community engagement on peace,
            conflict, security, gender and development that aims to contribute
            to Liberia’s national recovery, development, peace and gender
            equality. <br /> <br />
            The institute is part of the University of Liberia (formerly Liberia
            College), a publicly funded institution of higher learning. Unlike
            classical university institutes, KAICT is not only active in
            academic teaching and research but also maintains an extensive civic
            education and specialized training program targeting the interested
            public and professionals. <br /> <br />
            We offer a Master’s degree, diploma and certificate programs in
            Peace Studies and Conflict Transformation through the University of
            Liberia; engage in a wide-range of research; facilitate dialogue
            among key stakeholders through the Security Sector Reform Think Tank
            and the Mary Antoinette Brown Sherman Think Tank on gender and women
            in leadership; and implement outreach programs in communities to
            develop human resource capacity to transform conflicts, advance
            peace building, enhance security, gender sensitivity and inclusivity
            in Liberia. We thereby bridge the gap between policy-making,
            research and community engagement. <br /></p>
        </div>
    </section>
  );
};
export default WhyUs;
