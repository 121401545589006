const ComingSoonFooter=()=>{
    return(
<div className="coming-soon-footer absolute bottom-0 left-0 w-full py-6">
<div className="container flex justify-between items-center ">
    <p>Kofi Annan Institute for Conflict Transformation. Rights Reserved. Copyright 2022.</p>
    <div className="flex gap-4">
        <a href=""> <i className="fa fa-facebook text-24 text-white"></i></a>
        <a href=""> <i className="fa fa-twitter text-24 text-white"></i></a>
        <a href=""> <i className="fa fa-linkedin text-24 text-white"></i></a>


    </div>
    
</div>
</div>
    )
}
export default ComingSoonFooter