import { useState } from "react";
import { submitForm } from "../../services";
const ContactUs = () => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [aboutUs, setaboutUs] = useState("");
  const [message, setmessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      "First Name": firstName,
      "Last Name": lastName,
      "Company Name?": companyName,
      email: email,
      "How did you hear about us?": aboutUs,
      Message: message,
    };

    const tableRows = Object.entries(payload).map(
      ([question, answer]) => `
        <tr>
          <td>${question}</td>
          <td>${answer}</td>
        </tr>
      `
    );

    const table = `
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows.join("")}
          </tbody>
        </table>
      `;
    submitForm({
      title: email,
      categories: [4],
      status: "publish",
      content: table,
    }).then((res) => {

    });
  };
  const handleChange=(e)=>{

  }
  return (
    <section className="contact">
      <div className="contact-container container">
        <div className="contact-details">
          <div className="title">
            <h1>Contact Us</h1>
            <p>
              Share your ideas. Introduce your organization. Join Us.
            </p>
          </div>
          <div className="details">
            <div className="detail">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.27013 3.71L10.3401 2.87C11.1501 2.54 12.0801 2.93 12.4101 3.75L13.0401 5.29C13.3701 6.1 12.9801 7.03 12.1601 7.37L9.87013 8.3C10.1101 9.45 10.4801 10.8 11.0601 12.26C11.7101 13.91 12.4601 15.27 13.1401 16.36L15.4301 15.43C16.2501 15.1 17.1801 15.49 17.5101 16.31L18.1401 17.85C18.4701 18.67 18.0801 19.6 17.2601 19.93L15.1901 20.77L15.1501 20.79C12.9201 21.69 10.3501 20.82 9.19013 18.72C8.41013 17.31 7.64013 15.73 6.93013 13.96C6.29013 12.36 5.79013 10.83 5.41013 9.41C4.78013 7.08 6.00013 4.65 8.23013 3.74L8.27013 3.71Z"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>+231 77 627 7932 |+231 88 617 1990</span>
            </div>
            <div className="detail">
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.09 14.21H3.5C2.4 14.21 1.5 13.31 1.5 12.21V2.5C1.5 1.95 1.95 1.5 2.5 1.5H17.09C17.64 1.5 18.09 1.95 18.09 2.5V12.21C18.09 13.31 17.19 14.21 16.09 14.21Z"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.8501 2.13L9.2401 7.27C9.5701 7.5 10.0201 7.5 10.3501 7.27L17.7401 2.13"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>kofiannaninstitute.ul@gmail.com</span>
            </div>
            <div className="detail">
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.09 14.21H3.5C2.4 14.21 1.5 13.31 1.5 12.21V2.5C1.5 1.95 1.95 1.5 2.5 1.5H17.09C17.64 1.5 18.09 1.95 18.09 2.5V12.21C18.09 13.31 17.19 14.21 16.09 14.21Z"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.8501 2.13L9.2401 7.27C9.5701 7.5 10.0201 7.5 10.3501 7.27L17.7401 2.13"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>kofiannaninstitute@ul.edu.lr</span>
            </div>
            <div className="detail">
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.39 7.98998C14.39 12.14 10.34 17.13 8.68999 18.99C8.31999 19.4 7.67999 19.4 7.30999 18.99C5.65999 17.13 1.60999 12.14 1.60999 7.98998C1.60999 4.45998 4.46999 1.59998 7.99999 1.59998C11.53 1.59998 14.39 4.45998 14.39 7.98998Z"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.98993 10.5499C9.40378 10.5499 10.5499 9.40378 10.5499 7.98993C10.5499 6.57608 9.40378 5.42993 7.98993 5.42993C6.57608 5.42993 5.42993 6.57608 5.42993 7.98993C5.42993 9.40378 6.57608 10.5499 7.98993 10.5499Z"
                  stroke="#25518C"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>University of Liberia, Monrovia, Liberia</span>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7344501233483!2d-10.796255726742036!3d6.298583825739767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf09f94acaa3cf57%3A0x18bdf1482b6cfdd7!2sKofi%20Annan%20Institute%20for%20Conflict%20Transformation%20(KAICT)!5e0!3m2!1sen!2ske!4v1691151608937!5m2!1sen!2ske"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
         </div>
        <div className="contact-form">
          <form action="" onSubmit={handleSubmit}>
            <div className="names">
              <div className="control-group name">
                <label htmlFor="name">First Name</label>
                <br></br>
                <input
                  type="text"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                  }}
                />
              </div>
              <div className="control-group name">
                <label htmlFor="name">Last Name</label>
                <br></br>
                <input
                  type="text"
                  name="email"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => {
                    setlastName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="control-group">
              <label htmlFor="email">Email Address</label>
              <br></br>
              <input
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </div>
            <div className="control-group">
              <label htmlFor="company"> Name of Organization</label>
              <br></br>
              <input
                type="text"
                name=" Name of Organization"
                placeholder=" Name of Organization "
                value={companyName}
                onChange={(e) => {
                  setcompanyName(e.target.value);
                }}
              />
            </div>
            <div className="control-group">
              <label htmlFor="">Leave a Message?</label>
              <br></br>
              <textarea
                name="message"
                id=""
                cols="30"
                rows="10"
                placeholder="Type Your Message Here..."
                value={message}
                onChange={(e) => {
                  setmessage(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="control-group checkbox">
              <input type="checkbox" id="checkboxed" checked onChange={handleChange} />
              <label htmlFor="terms">
                By clicking on “Send Message” you agree to our Terms &
                Conditions and Privacy Statement.
              </label>
            </div>
            <div className="control-group">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
