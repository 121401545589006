import Footer from "../utils/footer";
import Navbar from "../utils/navbar";
import KacitOverview from "./kacit-overview";

const AboutOverview = () => {
    return ( 
        <div>
            <Navbar/>
            <KacitOverview/>
            <Footer/>
        </div>
     );
}
 
export default AboutOverview;