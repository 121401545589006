const Choices = () => {
  return (
    <section className="choices">
      <div className="choices-mask">
        <div className="choices-container container">
          <div className="choice-head">
            <p>Why Choose Us</p>
            <h1>Areas of Engagement</h1>
          </div>
          <div className="choice-content">
            <div className="row">
              <a href="/research">
              <div className="column choice">
                <h3>Research</h3>
                <p>
                  The Institute conducts research and scholarship in the field
                  of conflict resolution and peacebuilding, with a focus on
                  practical applications and real-world impact. Its research
                  informs its teaching and training activities and contributes
                  to the broader academic and policy discourse on peace and
                  conflict issues.
                </p>
              </div>
              </a>
          
              <a href="/teachingandtraining">
                <div className="column choice">
                  <h3>Teaching & Training</h3>
                  <p>
                    The Institute's primary focus is on providing high-quality
                    education and training in conflict resolution and
                    peacebuilding. It offers academic programs, training courses,
                    and professional development opportunities that equip
                    individuals with the knowledge, skills, and values needed to
                    address conflicts effectively.
                  </p>
                </div>
              </a>
              <a href="/outreachprograms">
                <div className="column choice">
                  <h3>Outreach Programs</h3>
                  <p>
                    The Institute is committed to engaging with communities
                    affected by conflicts and promoting dialogue and
                    reconciliation. It works closely with local partners to
                    develop and implement programs that address specific conflict
                    issues and build sustainable peace.
                  </p>
                </div>
              </a>
              <a href="/policyEngagement">
                <div className="column choice">
                  <h3>Policy Engagement</h3>
                  <p>
                    The Institute advocates for policies and practices that
                    promote conflict resolution and peacebuilding. It engages with
                    policymakers and stakeholders to promote evidence-based
                    approaches to conflict resolution and to inform policy
                    decisions.
                  </p>
                </div>
              </a>
              <a href="/partnershipsandcollaborations">
                <div className="column choice">
                  <h3>Partnerships and Collaboration</h3>
                  <p>
                    The Institute values partnerships and collaboration with
                    governments, civil society organizations, academic
                    institutions, and individuals. It works closely with these
                    stakeholders to advance its mission and to promote conflict
                    resolution and peacebuilding more broadly.
                  </p>
                </div>
              </a>


              <div className="column"></div>
            </div>
          </div>
          <a
          href="/kacitOverview"
          className="w-full bg-[#25518C] py-4 flex items-center justify-center text-white cursor-pointer"
        >
          Check Our Overview
        </a>
        </div>
      </div>
    </section>
  );
};
export default Choices;
